var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"safetyPurchase"},[_c('div',{staticClass:"safetyPurchase__content"},[_vm._m(0),_c('div',{staticClass:"safetyPurchase__content__section"},[_c('div',{staticClass:"safetyPurchase__content__section__boxes"},[_vm._m(1),_c('div',{staticClass:"safetyPurchase__content__section__boxes__box"},[_c('router-link',{attrs:{"to":"/reklamacje"}},[_c('img',{attrs:{"src":require("../assets/safetyPurchase/years.png"),"alt":"tba"}})]),_vm._m(2)],1),_c('div',{staticClass:"safetyPurchase__content__section__boxes__box"},[_c('router-link',{attrs:{"to":"/reklamacje"}},[_c('img',{attrs:{"src":require("../assets/safetyPurchase/box.png"),"alt":"tba"}})]),_vm._m(3)],1)])]),_c('div',{staticClass:"safetyPurchase__content__section"},[_c('div',{staticClass:"safetyPurchase__content__section__boxes"},[_c('div',{staticClass:"safetyPurchase__content__section__boxes__box"},[_c('router-link',{attrs:{"to":"/zdjecia"}},[_c('img',{attrs:{"src":require("../assets/safetyPurchase/photo.png"),"alt":"tba"}})]),_vm._m(4)],1),_vm._m(5),_vm._m(6)])]),_c('div',{staticClass:"safetyPurchase__content__section"},[_c('h4',[_vm._v("Zaufali nam")]),_c('transition',{attrs:{"name":"fade"}},[(this.trustId>0)?_c('div',{staticClass:"safetyPurchase__content__section__trust"},[_c('div',{staticClass:"safetyPurchase__content__section__trust__image"},[_c('img',{attrs:{"src":require("../assets/vat0/trust.png"),"alt":"tba"}})]),_c('div',{staticClass:"safetyPurchase__content__section__trust__texts"},[_c('h6',[_vm._v(_vm._s(this.trust.name[this.trustId]))]),_c('p',[_vm._v(_vm._s(this.trust.content[this.trustId]))])])]):_vm._e()])],1)]),_c('navbar'),_c('trust'),_c('ftr')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"safetyPurchase__content__header"},[_c('h1',[_c('span',[_vm._v("K")]),_vm._v(" - jak Korzyści z zakupów")]),_c('h4',[_vm._v("Bezpieczne zakupy w Kompre")]),_c('p',[_vm._v("Chcemy, żeby zakupy w naszym sklepie były przyjemne, szybkie i bezpieczne, dlatego ograniczyliśmy formalności do minimum, wydłużyliśmy gwarancję i czas na zwrot. Pamiętaj, że w przypadku dodatkowych pytań zawsze jesteśmy do Twojej dyspozycji.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"safetyPurchase__content__section__boxes__box"},[_c('div',{staticClass:"safetyPurchase__content__section__boxes__box__image"},[_c('img',{attrs:{"src":require("../assets/safetyPurchase/time.png"),"alt":"tba"}})]),_c('div',{staticClass:"safetyPurchase__content__section__boxes__box__texts"},[_c('p',[_vm._v("Szybka i darmowa dostawa w 24h, już przy zakupach od 500zł. Gdy otrzymamy Twoje zamówienie, od razu zabieramy się za przygotowanie i pakowanie")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"safetyPurchase__content__section__boxes__box__texts"},[_c('p',[_vm._v("Gwarancja door to door nawet do 3 lat! Zgłoszenie usterki przez nasz formularz zajmuje tylko minutę. Szybko i bezpłatnie naprawimy Twój sprzęt.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"safetyPurchase__content__section__boxes__box__texts"},[_c('p',[_vm._v("Szybki, bezproblemowy i najważniejsze darmowy zwrot do paczkomatu do 30 dni. Nie musisz się już spieszyć z wypróbowaniem sprzętu.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"safetyPurchase__content__section__boxes__box__texts"},[_c('p',[_vm._v("Ułatwimy Ci podjęcie ostatecznej decyzji. Przed zakupem masz możliwość zobaczenia zdjęć konkretnego laptopa, który do Ciebie trafi.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"safetyPurchase__content__section__boxes__box"},[_c('div',{staticClass:"safetyPurchase__content__section__boxes__box__image"},[_c('img',{attrs:{"src":require("../assets/safetyPurchase/checked.png"),"alt":"tba"}})]),_c('div',{staticClass:"safetyPurchase__content__section__boxes__box__texts"},[_c('p',[_vm._v("Każdy sprzęt jest u nas dokładnie sprawdzony i przetestowany. Dodatkowo posiada już wgrane oprogramowanie i jest gotowy do działania.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"safetyPurchase__content__section__boxes__box"},[_c('div',{staticClass:"safetyPurchase__content__section__boxes__box__image"},[_c('img',{attrs:{"src":require("../assets/safetyPurchase/family.png"),"alt":"tba"}})]),_c('div',{staticClass:"safetyPurchase__content__section__boxes__box__texts"},[_c('p',[_vm._v("Honorujemy kartę dużej rodziny. Wyślij do nas wiadomość z numerem karty na sklep@kompre.pl i otrzymaj 5% rabatu na wszystkie produkty.")])])])
}]

export { render, staticRenderFns }