<template>
    <div class="safetyPurchase">
        <div class="safetyPurchase__content">
            <div class="safetyPurchase__content__header">
                <h1><span>K</span> - jak Korzyści z zakupów</h1>
                <h4>Bezpieczne zakupy w Kompre</h4>
                <p>Chcemy, żeby zakupy w naszym sklepie były przyjemne, szybkie i bezpieczne, dlatego ograniczyliśmy formalności do minimum, wydłużyliśmy gwarancję i czas na zwrot. Pamiętaj, że w przypadku dodatkowych pytań zawsze jesteśmy do Twojej dyspozycji.</p>
            </div>
            <div class="safetyPurchase__content__section">
                <div class="safetyPurchase__content__section__boxes">
                    <div class="safetyPurchase__content__section__boxes__box">
                        <div class="safetyPurchase__content__section__boxes__box__image">
                            <img src="../assets/safetyPurchase/time.png" alt="tba">
                        </div>
                        <div class="safetyPurchase__content__section__boxes__box__texts">
                            <p>Szybka i darmowa dostawa w 24h, już przy zakupach od 500zł. Gdy otrzymamy Twoje zamówienie, od razu zabieramy się za przygotowanie i pakowanie</p>
                        </div>
                    </div>
                    <div class="safetyPurchase__content__section__boxes__box">
                        <router-link to="/reklamacje">
                            <img src="../assets/safetyPurchase/years.png" alt="tba">
                        </router-link>
                        <div class="safetyPurchase__content__section__boxes__box__texts">
                            <p>Gwarancja door to door nawet do 3 lat! Zgłoszenie usterki przez nasz formularz zajmuje tylko minutę. Szybko i bezpłatnie naprawimy Twój sprzęt.</p>
                        </div>
                    </div>
                    <div class="safetyPurchase__content__section__boxes__box">
                        <router-link to="/reklamacje">
                                <img src="../assets/safetyPurchase/box.png" alt="tba">
                        </router-link>
                        <div class="safetyPurchase__content__section__boxes__box__texts">
                            <p>Szybki, bezproblemowy i najważniejsze darmowy zwrot do paczkomatu do 30 dni. Nie musisz się już spieszyć z wypróbowaniem sprzętu.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="safetyPurchase__content__section">
                <div class="safetyPurchase__content__section__boxes">
                    <div class="safetyPurchase__content__section__boxes__box">
                        <router-link to="/zdjecia">
                            <img src="../assets/safetyPurchase/photo.png" alt="tba">
                        </router-link>
                        <div class="safetyPurchase__content__section__boxes__box__texts">
                            <p>Ułatwimy Ci podjęcie ostatecznej decyzji. Przed zakupem masz możliwość zobaczenia zdjęć konkretnego laptopa, który do Ciebie trafi.</p>
                        </div>
                    </div>
                    <div class="safetyPurchase__content__section__boxes__box">
                        <div class="safetyPurchase__content__section__boxes__box__image">
                            <img src="../assets/safetyPurchase/checked.png" alt="tba">
                        </div>
                        <div class="safetyPurchase__content__section__boxes__box__texts">
                            <p>Każdy sprzęt jest u nas dokładnie sprawdzony i przetestowany. Dodatkowo posiada już wgrane oprogramowanie i jest gotowy do działania.</p>
                        </div>
                    </div>
                    <div class="safetyPurchase__content__section__boxes__box">
                        <div class="safetyPurchase__content__section__boxes__box__image">
                            <img src="../assets/safetyPurchase/family.png" alt="tba">
                        </div>
                        <div class="safetyPurchase__content__section__boxes__box__texts">
                            <p>Honorujemy kartę dużej rodziny. Wyślij do nas wiadomość z numerem karty na sklep@kompre.pl i otrzymaj 5% rabatu na wszystkie produkty.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="safetyPurchase__content__section">
                <h4>Zaufali nam</h4>
                <transition name="fade"> 
                <div class="safetyPurchase__content__section__trust" v-if="this.trustId>0">
                    <div class="safetyPurchase__content__section__trust__image">
                        <img src="../assets/vat0/trust.png" alt="tba">
                    </div> 
                    <div class="safetyPurchase__content__section__trust__texts">
                        <h6>{{this.trust.name[this.trustId]}}</h6>
                        <p>{{this.trust.content[this.trustId]}}</p>
                    </div>
                </div>
                </transition>
            </div>
        </div>
        <navbar/>
        <trust/>
        <ftr/>
    </div>
</template>
<script>
    // Imports
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";

    export default {
        data() {
            return {
                trustId: 1,
                trust: {
                    name: [
                        '',
                        'Komenda Wojewódzka Policji we Wrocławiu',
                        'Starostwo Powiatowe w Kłodzku',
                        'Wojewódzki Szpital Specjalistyczny w Słupsku'
                    ],
                    content:[
                        '',
                        '"Firma Kompre wykonała dla naszej jednostki dostawę poleasingowego sprzętu komputerowego. Umowa została wykonana zgodnie z przyjętymi warunkami."',
                        '"Bardzo cenną zaletą firmy KOMPRE jest szeroko pojęta elastyczność, dbałość o interes klienta, właściwe zrozumienie potrzeb oraz umiejętność dostosowania do standardów obowiązujących w danej firmie."',
                        '"Możemy polecić Firmę Kompre jak solidnego dostawcę dla innych Zamawiających"'
                    ]
                }
            }
        },
        methods:
        {
            loopForTrust(){
                let n = 1;
                setInterval(()=>{
                    if(n < this.trust.name.length-1){
                        n++
                        this.trustId = 0;
                        setTimeout(()=>{
                            this.trustId = n;
                        },500)
                    }else{
                        this.trustId = 0;
                            setTimeout(()=>{
                            n = 1
                            this.trustId = 1;
                            },500)
                    }
                },8000)
            }
        },
        mounted()
        {
            window.scrollTo(0,0);
            this.loopForTrust();
        },
        components: {navbar,trust,ftr},
    }
</script>
<style lang="scss" scoped>
    @import '../styles/variables';

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


    .safetyPurchase
    {
        width: 100vw;
        height: auto;
        margin-top: 10.527em;
        &__content
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            &__header{
                margin-top: 1.563rem;
                margin-bottom: 1.563rem;
                width: 55%;
                h1{
                    font-size: 1.563rem;
                    font-weight: 600;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    margin-bottom: 0.41rem;

                    span{
                        font-size: 1.953rem !important;
                        color: #f01827;
                    }
                }
                h4{
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-size: 1rem !important;
                    font-weight: 500;
                    margin-bottom: 0.21rem;
                }
                p{
                    font-size: 1rem;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                }
            }
            &__section{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 1.953rem;
                h4{
                    font-size: 1.25rem;
                    text-align: center;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 1.25rem;
                    font-weight: 500;
                }
                &__boxes{
                    display: flex;
                    justify-content: space-between;
                    width: 80%;
                    &__box{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 30%;
                        height: auto;
                        &__image{
                            display: flex;
                            justify-content: center;
                            max-height: 7.451rem;
                            width: 50%;
                            margin-bottom: 1.25rem;
                            img{
                                max-height: 100%;
                                max-width: 100%;
                            }
                        }
                        a{
                            display: flex;
                            justify-content: center;
                            max-height: 7.451rem;
                            width: 50%;
                            margin-bottom: 1.25rem;
                            img{
                                max-height: 100%;
                                max-width: 100%;
                            }
                        }
                        &__texts{
                            margin-top: auto;
                            margin-bottom: auto;
                            p{
                                font-size: 1rem;
                                font-weight: 400;
                                padding: 0;
                                margin: 0;
                                text-align: center;
                                span{
                                display: block;
                                }
                            }
                        }
                    }
                }
                &__trust{
                    display: flex;
                    height: 100%;
                    width: 52%;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    &__image{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        max-height: 5.96rem;
                        width: 20%;
                        padding-right: 1rem;
                        img{
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    &__texts{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 80%;
                        h6{
                            font-size: 1rem;
                            font-weight: 500;
                            margin: 0;
                            padding: 0;
                            margin-bottom: 0.21rem;
                        }
                        p{
                            margin: 0;
                            padding: 0;
                            font-weight: 400;
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
    }


    @media (max-width: 1024px)
    {
     .safetyPurchase__content{
         &__header{
             width: 80%;
         }
         &__section{
            &__boxes{
                width: 100%;
            }  
            &__trust{
                width: 80%;
                justify-content: center;
                align-items: center;
                &__image{
                    height: 100%;
                    width: 5.96rem;
                }
            }
        }
     }

    .download{
        flex-direction: row !important;
    }
    }
    @media (max-width: 769px) {
    .safetyPurchase{
        margin-top: 4.768em;
        &__content{
            &__header{
                width: 100%;
            }
            &__section{
                margin-bottom: 0;
                &__boxes{
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    &__box{
                        margin-bottom: 1.563rem;
                        width: 80%;
                        height: auto;
                        &__image{
                            width: 7.451rem;
                            height: auto;
                        }
                        a{
                            width: 7.451rem;
                            height: auto;
                        }
                    }
                }
                &__trust{
                    width: 100%;
                    margin-bottom: 1.563rem;
                }
            }
        }
    }
}

    @media (max-width: 370px) {
    .safetyPurchase{
        &__content{
            &__section{
                margin-bottom: 0.64rem;
                h4{
                    font-size: 1rem;
                    margin-bottom: 0.41rem;
                }
                &__boxes{
                    &__box{
                        &__image{
                            width: 4.768rem;
                            height: auto;
                            margin-bottom: 0.64rem;
                        }
                        a{
                            width: 4.768rem;
                            height: auto;
                            margin-bottom: 0.64rem;
                        }
                    }
                }
                &__trust{
                    flex-direction: column;
                    margin-top: 0.41rem;
                    margin-bottom: 1rem;
                    &__image{
                        width: 4.768rem;
                        margin-bottom: 0.64rem;
                    }
                    &__texts{
                        align-items: center;
                        p{
                            text-align: center;
                        }
                    }
                }
            }
            &__header{
                margin-top: 1rem;
                margin-bottom: 1rem;
                h1{
                    font-size: 1.25rem !important;
                }
            }
        }
    }
    .download{
        margin-top: 0.41rem;
    }
}

</style>